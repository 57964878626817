import React, { useState, useEffect } from 'react';
import { getUser, resetUserSession } from './service/AuthService';
import LocationFinder from './service/LocationFinderService';
import { useNavigate } from 'react-router-dom';
//import './index.css';
import './premiumContentStyle.css'
import { HiOutlineLogout } from 'react-icons/hi';
import { BsCalendarCheck } from 'react-icons/bs';
import { CgProfile } from 'react-icons/cg';
import { PiQuestion } from "react-icons/pi";
import Logo from './image/cleanntidyLogo.png';
import { RiHome3Line } from "react-icons/ri";
import { Header } from './Header';
import maleCleanerImage from './image/male-cleaner.png';
import { PiSunDuotone } from "react-icons/pi";
import ColumTaskBar from './ColumnTaskBar';
import QuickAccessButton from './QuickAccessButton';
import TextList from './TextList';


const PremiumLayout = ({ children }) => {
    return (
      <div className="premium-layout">
        {/* You can add any additional layout elements here */}
        {children}
      </div>
    );
};

const PremiumContent = (props) => {
    const navigate = useNavigate();
    const user = getUser();
    const name = user !== 'undefined' && user ? user.name : '';
    const email = user !== 'undefined' && user ? user.email : '';

    const [date, setDate] = useState('');

    //const loginUsername = user !== 'undefined' && user ? user.username : '';

    const handleOnClickQuickAccess = (path) => {
        navigate(path)
    }

    const [isActive, setIsActive] = useState(false);

        //just refreshing at first
        useEffect(() => {
            // Check if the page has been refreshed in this session
            const hasRefreshed = sessionStorage.getItem('hasRefreshed');
    
            // If the page hasn't been refreshed, reload the page
            if (!hasRefreshed) {
                sessionStorage.setItem('hasRefreshed', 'true'); // Mark that we have refreshed
                window.location.reload(); // Reload the page
            }
    
            // This will prevent further reloads after the first one
        }, []);
        

    useEffect(() => {
        const today = new Date();
        const day = today.getDate();
        const month = today.getMonth() + 1; // Months are zero-based
        const year = today.getFullYear();

        const formattedDate = `${month}/${day}/${year}`; // for MM/DD/YYYY
        // const formattedDate = `${day}/${month}/${year}`; // for DD/MM/YYYY

        setDate(formattedDate);
    }, [])
    
    return (
        <div>
        <div className='background-premium'></div>
        <header className='account-layout'>
            <div className='tittle-root'>
                <img src={Logo}
                    style={{ width: '1.75em', height: '1.5em', objectFit: 'cover',
                    }}
                />
                <h2 id='clean-n-tidy-tittle'>
                    Clean 'n' Tidy
                </h2>
            </div>

        </header>
        <TextList className="main-search-bar" />

        <ColumTaskBar />

        <div className='welcome-section'>

                <p><span className='welcome-date'>{date}</span> <br />
                <span>
                    <div id='sunWrapper'>
                        <PiSunDuotone id='sunIcon' />
                    </div>
                </span> Welcome {name.split(' ')[0]} < br />
                </p>
            </div>

            {/* <img src={maleCleanerImage} id='male-cleaner-premium' /> */}

        <div className='main-content'>
        <h2>Quick Access</h2>
            <div className='convenient-root'>
                <LocationFinder />
                <section className='right-block-root'>

                        {(email === 'thienbao1084@gmail.com' ||
                    email === 'cleanntidy.au@outlook.com' ||
                    email === 'test2@gmail.com') ? (
                        <>
                    <div className='staff-management-block' onClick={() => handleOnClickQuickAccess('/staff-management')}>
                        <section>
                            Staff Management
                        </section>
                        <div>
                        View shift recodes, tasks status and more
                        </div>
                    </div>

                    <div className='add-edit-jobs-block' onClick={() => handleOnClickQuickAccess('/add-or-edit-jobs')}>
                        <section>
                                Add/Edit Jobs
                        </section>
                            <div>Add new and edit job controls here</div>
                    </div>
                    </>
                    ) : (
                        null
                    )}

                    <div className='calendar-schedule-block' onClick={ () => handleOnClickQuickAccess('/calendarsearching')}>
                        <section>
                                Calendar Schedule
                        </section>
                            <div>See your today's works</div>
                    </div>
                </section>
            </div>
        </div>
        
        
        </div>
        

    )
}
export default PremiumContent;