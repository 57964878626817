module.exports = {
    getUser: function() {
        // const user = sessionStorage.getItem('user');
        const user = localStorage.getItem('user');
        if (user === 'undefined' || !user) {
            return null;
        } else {
            return JSON.parse(user);
        }
    },

    getToken: function() {
        // return sessionStorage.getItem('token');
        return localStorage.getItem('token');
    },

    setUserSession: function(user, token) {
        sessionStorage.setItem('user', JSON.stringify(user));
        sessionStorage.setItem('token', token);

        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', token);

    },

    resetUserSession: function() {
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('token');

        localStorage.removeItem("user");
        localStorage.removeItem("token")
    }

}