import React from 'react';
import './index.css'
import Logo from './image/cleanntidyLogo.png';
import background from './image/home-img.png';
import { Navigate } from 'react-router-dom';
import AccessInformation from './service/AccessInformation';
import { useEffect, useState } from 'react';
import { getUser, getToken, setUserSession, resetUserSession } from "./service/AuthService";
import axios from 'axios';

const { requestConfig ,verifyTokenAPIURL } = AccessInformation;


const Home = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [authenticating, setAuthenticating] = useState(true); // Set to true initially to show loading state

    //just refreshing at first
    useEffect(() => {
        // Check if the page has been refreshed in this session
        const hasRefreshed = sessionStorage.getItem('hasRefreshed');

        // If the page hasn't been refreshed, reload the page
        if (!hasRefreshed) {
            sessionStorage.setItem('hasRefreshed', 'true'); // Mark that we have refreshed
            window.location.reload(); // Reload the page
        }

        // This will prevent further reloads after the first one
    }, []);

    useEffect(() => {
        const token = getToken(); // Fetch the token from storage
        const user = getUser();   // Fetch the user from storage
        
        // If no token or user is found, stop authenticating
        if (!token || !user || !user.username) {
            setIsAuthenticated(false);
            setAuthenticating(false);
            return;
        }

        // Request body to be sent in the verification request
        const requestBody = {
            user: user,  // The user object with username
            token: token // The token from storage
        };

        // Send axios POST request to verify the token
        axios.post(verifyTokenAPIURL, requestBody, requestConfig)
            .then((response) => {
                // If the response is successful, save the user and token
                setUserSession(response.data.user, response.data.token);
                setIsAuthenticated(true);
                setAuthenticating(false);
            })
            .catch((error) => {
                // If verification fails, reset the session
                resetUserSession();
                setIsAuthenticated(false);
                setAuthenticating(false);
            });
    }, []);

    if (authenticating) {
        return <div>Loading...</div>; // Show a loader while verifying
    }

    if (isAuthenticated) {
        return <Navigate to="/premium-content" />; // Redirect to premium content if authenticated
    }

    return (
        <div>
            <section className='logo' style={{margin: "5% auto", textAlign: 'center', top: '10em'}}>
                <img src={Logo}
                style={{ width: '5em', height: '5em', objectFit: 'cover', marginTop: '4em'
                // position: 'absolute',
                // top: '10em',
                // left: '10em'
                
                }} />
            </section>
            
            <section style={{ textAlign: 'center',
                                margin: '0 auto',
                              width: '21em'
                            }}>
                <h1 className='home-title'>Clean 'n' Tidy <br /> Property Services</h1>
            </section>
            {/* <img src={background} className='home-image' /> */}
            
        </div>
    )
}

export default Home;